<template>
	<div class="row"> 
		<recess-multi-select
			v-model="localSubsidiesOptions"
			:selected-options="localSubsidiesOptions"
			:options="subsidiesOptions"
			:enable-preview-selected-items="true"
			:label-text="PROVIDER.Portfolio.CourseEdit.ExtraOptionsTab.Subsidies.Label"
			:select-options-text="PROVIDER.Portfolio.CourseEdit.ExtraOptionsTab.Subsidies.Placeholder"
			:class="`col-6 qa-course-subsidies`"
			@input="(newValue) => setSubsidies(newValue)"
		/> 
	</div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json' 
import { getEnumList } from '@/../../shared/api/SharedClient'
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

export default { 
	name: 'Subsidies',
	props: {
		subsidyValue: {
			type: Array,
			default: null
		}
	},
	data() {
		return {
			PROVIDER, 
			subsidiesOptions: [],
			localSubsidiesOptions: []
		}
	},
	watch: {
		subsidyValue: {
            handler() {
				this.localSubsidiesOptions = JSON.parse(JSON.stringify(this.subsidyValue))
            },
            immediate: true 
        }
	},
	mounted() { 
		this.$nextTick(() => { 
			this.getsubsidies() 
			this.localSubsidiesOptions = JSON.parse(JSON.stringify(this.subsidyValue)) 
		})
	}, 
	methods: { 
		setSubsidies(newValue) { 
			this.localSubsidiesOptions = newValue
			this.$emit('triggerSubsidiesValue', this.localSubsidiesOptions)
		},
		async getsubsidies() {
			try {
				const response = await getEnumList(
					process.env.VUE_APP_PROVIDER_API_URL, 
					API_CALL_URL_PATHS.enumerations, 
					'Subsidy', 
					null, 
					null, 
					false
				)

				if (!response) return
				this.subsidiesOptions = response.map((subsidie) => {
					if(subsidie.value === 'STAP'){
						subsidie.disabled = true;
					}
					// if (subsidie.value === 'NLContinuesToLearn') {
					// 	subsidie.disabled = true
					// }
					// https://evident-digital.atlassian.net/browse/ED-19356
					// TODO this is to be added back after Dec 31st  2022
					return subsidie 
				})
			} catch (error) {
				console.error('Something went wrong while retrieving subsidies', error)
			}
		}
	}
}
</script>